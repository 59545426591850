@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;1,300&family=Vesper+Libre:wght@400;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.9em;
  margin: 0 auto;
}

.maxwidth{
  max-width: 1100px;
  margin: 0 auto;
}

img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: black;
}
